<template>
  <LayoutEditorToolbar :buttons="buttons" class="toolbar-style">
    <template v-slot:button="{ button }">
      <ToolButton
        :tooltip="button.tooltip"
        :icon="button.icon"
        :active="button.name === toolCategory"
        :color="button.color"
        @clicked="() => onChangeToolCategory(button)"
        topTooltip
      ></ToolButton>
    </template>
    <ColorSelect
      slot="append"
      ref="colselect"
      :value.sync="colorValue"
      @color="(color) => (internalColor = color)"
    ></ColorSelect>
  </LayoutEditorToolbar>
</template>

<script>
import ColorSelect from "@/components/pickers/color/ColorSelect";
import LayoutEditorToolbar from "@/components/editors/toolbars/LayoutEditorToolbar";
import ToolsMixin from "@/mixins/editors/ToolsMixin";

const CATEGORY = 0;
const TOOL = 1;
const ACTION = 2;

export default {
  mixins: [ToolsMixin],
  computed: {
    internalColor: {
      get() {
        return this.toolColor;
      },
      set(value) {
        this.changeToolColor(value);
      },
    },
  },
  data() {
    return {
      colorValue: 0,
      buttons: [
        {
          tooltip: "Strzałka",
          name: "arrow",
          icon: require("@/assets/editor/arrow.svg"),
          kind: TOOL,
        },
        {
          tooltip: "Ołówek",
          name: "pencil",
          icon: require("@/assets/editor/pencil.svg"),
          kind: CATEGORY,
        },
        {
          tooltip: "Napis",
          name: "text",
          icon: require("@/assets/editor/text.svg"),
          kind: TOOL,
        },
        {
          separator: true,
        },
        {
          tooltip: "Zaznacz/Przesuń",
          name: "selectMode",
          icon: require("@/assets/editor/move.svg"),
          kind: TOOL,
        },
        {
          tooltip: "Cofnij",
          name: "undo",
          icon: require("@/assets/editor/undo.svg"),
          kind: ACTION,
        },
        {
          tooltip: "Przywróć",
          name: "redo",
          icon: require("@/assets/editor/redo.svg"),
          kind: ACTION,
        },
        {
          tooltip: "Usuń",
          name: "clear",
          color: "error",
          icon: require("@/assets/editor/clear.svg"),
          kind: ACTION,
        },
      ],
    };
  },
  components: {
    ColorSelect,
    LayoutEditorToolbar,
    ToolButton: () => import("@/components/editors/ToolButton"),
  },
  methods: {
    onChangeToolCategory(button) {
      if (button.kind == CATEGORY || button.kind == TOOL) {
        this.changeToolCategory(button.name);
      }

      if (button.kind == TOOL) {
        this.changeTool(button.name);
      }

      if (button.kind == ACTION) {
        this.$emit(button.name);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.colselect.selectColor(this.internalColor);
      this.checkIfToolCategoryExists();
    });
  },
};
</script>

<style scoped>
.toolbar-style {
  background-color: #f6f7fb !important;
}
.break {
  height: 1px !important;
  width: 100% !important;
}
</style>